// src/components/TextBox.js

import React from 'react';
import './QuoteBox.css';

function QuoteBox({ content, italicLine, textColor = "white" }) {
    const textStyle = { color: textColor }; // Style object for dynamic text color
    const boxStyle = { borderColor: textColor }; // Style object for dynamic border color

    return (
        <div className="quote-box" style={boxStyle}> {/* Apply dynamic border color */}
            <div className="quote-body" style={textStyle}>{content}</div>
            {italicLine && <div className="italic-line" style={textStyle}>{italicLine}</div>}
        </div>
    );
}

export default QuoteBox;
