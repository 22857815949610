import React from 'react';
import './SpotifyBox.css';

function SpotifyBox({ playlistUrl }) {
    return (
        <div className="spotify-box">
            <iframe
                src={`https://open.spotify.com/embed/playlist/${playlistUrl}`}
                width="100%"
                height="380"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title="Spotify Playlist"
            ></iframe>
        </div>
    );
}

export default SpotifyBox;
