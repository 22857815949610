// src/pages/AboutMe.js

import React from 'react';
import CVComponent from '../AboutMeComponents/CVComponent';
import BlogComponent from '../AboutMeComponents/BlogComponent';
import './AboutMe.css';

function AboutMe() {
    return (
        <div className="about-me">
            <div className="cv-section">
                <CVComponent />
            </div>
            <div className="blog-section">
                <BlogComponent />
            </div>
        </div>
    );
}

export default AboutMe;
