import React, { useState } from 'react';
import './ImageBox.css';

function ImageBox({ thumbnailSrc, fullSrc, alt, title, caption, link, onClick }) {
    const handleClick = () => {
        onClick(fullSrc);
    };

    const imageElement = (
        <div className="image-box" onClick={handleClick}>
            {title && <div className="caption-top">{title}</div>}
            <img src={thumbnailSrc} alt={alt} />
            {caption && <div className="caption-bottom">{caption}</div>}
        </div>
    );

    return link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {imageElement}
        </a>
    ) : (
        imageElement
    );
}

export default ImageBox;
