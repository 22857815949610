// src/components/BlogComponent.js

import React from 'react';
import BlogPost from './BlogPost'; // Import the BlogPost component
import './BlogComponent.css';

function BlogComponent() {
    return (
        <div className="blog-component">
            <h2>Latest Posts</h2>

            <BlogPost
                title="Released to the Appstore"
                body="I released my first app to the Appstore. Spent a weekend developing it and learning about the procedure of appstore releasing. It's a simple calendar & note-taking tool."
                link="https://apps.apple.com/us/app/slowdowning/id6670307084"
                linkText="SlowDowning on the Appstore"
                italicLine="Published on September 4, 2024"
            />

            <BlogPost
                title="Upcoming trip to Bali"
                body="I have a trip to Indonesia and Thailand planned. I'm excited to explore some of South East Asia and will definitely bring my camera along."
                italicLine="Published on September 1, 2024"
            />

            <BlogPost
                title="New Instagram post"
                body="I took some interest in photography recently and posted a new photo on Instagram."
                link="https://www.instagram.com/basilfurrer"
                linkText="Check it out..."
                italicLine="Published on August 13, 2024"
            />

            <BlogPost
                title="Starting my internship"
                body="I started my first internship as a software engineer. Looking forward to learning a lot..."
                italicLine="Published on July 1, 2024"
            />

            {/* Add more BlogPost components as needed */}
        </div>
    );
}

export default BlogComponent;
