import React from 'react';
import './BlogComponent.css'; // Use the same CSS file to maintain consistent styling

function BlogPost({ title, body, link, linkText, italicLine }) {
    return (
        <div className="post">
            <h3>{title}</h3>
            <p>{body}</p>
            {link && linkText && <a href={link} target="_blank" rel="noopener noreferrer" className="text-link">
                {linkText}
            </a>}
            {italicLine && <p className="italic">{italicLine}</p>}
        </div>
    );
}

export default BlogPost;
