import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home';
import AboutMe from './components/Pages/AboutMe';
import SlowDowning from './components/Pages/SlowDowning'; // Import the SlowDowning component
import Navbar from './components/Utilities/Navbar'; // Import the new Navbar component
import './App.css';

function App() {
  return (
      <Router>
        <div className="App">
          <Navbar /> {/* Use the Navbar component here */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-me" element={<AboutMe />} />
              <Route path="/SlowDowning" element={<SlowDowning />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
