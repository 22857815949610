// src/components/TextBox.js

import React from 'react';
import './TextBox.css';

function TextBox({ title, body, url, urlText = "Read more", italicLine, textColor = "white" }) {
    const textStyle = { color: textColor }; // Style object for dynamic text color
    const boxStyle = { borderColor: textColor }; // Style object for dynamic border color

    return (
        <div className="text-box" style={boxStyle}> {/* Apply dynamic border color */}
            {title && <div className="text-title" style={textStyle}>{title}</div>}
            <div className="text-body" style={textStyle}>{body}</div>
            {url && (
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-link">
                    {urlText}
                </a>
            )}
            {italicLine && <div className="italic-line" style={textStyle}>{italicLine}</div>}
        </div>
    );
}

export default TextBox;
