import React from 'react';
import './BackgroundImage.css';

function BackgroundImage({ image }) {  // Accept image as a prop
    return (
        <div
            className="background-image"
            style={{
                backgroundImage: `url(${image})`,  // Use the prop to set the background image
            }}
        ></div>
    );
}

export default BackgroundImage;
