//new website page with a button that navigates to a different website

import React from 'react';
import './SlowDowning.css';

function SlowDowning() {
    return (
        <div className="slowdowning">
            <h1>SlowDowning</h1>
            <p>SlowDowning is a simple calendar & note-taking tool. It's a project I developed in a weekend to learn
                about the procedure of appstore releasing. You can download it on the Appstore.</p>
            <a href="https://apps.apple.com/us/app/slowdowning/id6670307084" target="_blank" rel="noreferrer">Download
                SlowDowning</a>

            <h2>Privacy Policy for SlowDowning</h2>
            <h3>Effective Date: 04.09.2024</h3>
            <p>Thank you for choosing SlowDowning. Your privacy is important to us. This Privacy Policy explains how SlowDowning collects, uses, and discloses information about you.</p>
            <h3>1. Information Collection and Use</h3>
            <p>SlowDowning does not collect any personal data from its users. All data generated through the use of the app is stored locally on your device. We do not have access to any of your personal information or app usage data.</p>
            <h3>2. Data Storage</h3>
            <p>All data created and managed within SlowDowning remains on your device. We do not store any data on external servers, nor do we have access to your data. You are solely responsible for the management and security of your data on your device.</p>
            <h3>3. Third-Party Services</h3>
            <p>SlowDowning does not use any third-party services that could collect data about you or your device.</p>
            <h3>4. Changes to This Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            <h3>5. Contact Us</h3>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at onlineuserinformation@gmail.com.</p>
            <p>By using SlowDowning, you agree to the terms outlined in this Privacy Policy.</p>
        </div>
    );
}

export default SlowDowning;