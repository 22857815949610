// src/components/Boxes/SocialBox.js

import React from 'react';
import { FaInstagram, FaGithub } from 'react-icons/fa'; // Import Instagram and GitHub icons from react-icons
import './SocialBox.css';

function SocialBox({ title, iconColor = "black", textColor = "black", socialLinks = [] }) {
    const textStyle = { color: textColor }; // Style object for dynamic text color

    const renderIcon = (platform) => {
        switch (platform) {
            case "instagram":
                return <FaInstagram color={iconColor} size={30} />;
            case "github":
                return <FaGithub color={iconColor} size={30} />;
            // Add more cases here if you want to support additional platforms
            default:
                return null;
        }
    };

    return (
        <div className="social-box" style={{ borderColor: textColor }}>
            {title && <div className="text-title" style={textStyle}>{title}</div>}
            <div className="social-content" style={{ color: textColor }}>
                {socialLinks.map(({ platform, link }, index) => (
                    <a
                        key={index}
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-icon"
                    >
                        {renderIcon(platform)}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default SocialBox;
